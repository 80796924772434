const INITIAL_STATE = {
	onBack: null,
	title: ''
}

const header = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_HEADER':
			document.title = (action.payload?.title || '') + (action?.payload?.title ? ' | ' : '') + 'Splendor Productos de Limpieza en Venezuela';
            return action.payload;
            break;
        default: 
        	return state;
        	break;
	}
}

export default header;