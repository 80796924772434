import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'reducers';
import { WhatsappIcon, InstagramYellowIcon, CartWhiteIcon } from 'assets/icons';
import { Input, Button, Modal } from 'components';
import { Globals, Constants } from 'utils';
import { LogoWhite, WhiteImage, LogoSplendor, CartImage } from 'assets/img';
import { CategoriesIcon, SearchWhiteIcon, LockWhiteIcon, ProfileWhiteIcon, LogoutWhiteIcon, ProductsIcon, BoxIcon, LogoutIcon, ProfileIcon, LockIcon, ArrowGrayIcon, SearchGrayIcon } from 'assets/icons';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";
import { ProductService } from 'services';
import ModalPassword from 'screens/password';
import ModalProfile from 'screens/profile';

declare var window: any;

class PublicHeader extends React.Component<any> {

	timer: any = undefined;

	state = {
		form: {
			search: ''
		},
		open_categories: false,
		autocomplete_options: [],
		open_cart: false,
		visible_profile: false,
		visible_password: false,
		show_search: false
	}

	change = (e: any,callback = () => {}) => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		},callback);
	}

	loadAutocomplete = async () => {
		if (this.state.form.search != '') {
			const res: any = await ProductService.public.autocomplete({
				withoutLoading: true,
				...this.state.form
			});
			this.setState({
				autocomplete_options: res.products
			});
		}
		else {
			this.setState({
				autocomplete_options: []
			});
		}		
	}

	onClosePassword = () => {
		this.setState({
			visible_password: false
		});
	}

	onCloseProfile = () => {
		this.setState({
			visible_profile: false
		});
	}

	logout = () => {
		Globals.confirm('¿Desea cerrar sesión?',() => {
			this.props.dispatch({
				type: 'SET_USER',
				payload: null
			});
			this.props.history.replace('/');
			Globals.quitLoading();
		});
	}

	render() {
		const { contact_info, product_types } = this.props;
		const { visible_password, visible_profile } = this.state;

		return (
			<div id="public-header">
				<Modal
		          className="modal-password"
		          visible={ visible_password }
		          title=""
		          onClose={ () => this.onClosePassword() }
		        >
		          {/*
		          // @ts-ignore */}
		          <ModalPassword
		          	onClose={ () => this.onClosePassword() }
		          />
		      	</Modal>

		      	<Modal
		          className="modal-profile"
		          visible={ visible_profile }
		          title=""
		          onClose={ () => this.onCloseProfile() }
		        >
		          {/*
		          // @ts-ignore */}
		          <ModalProfile
		          	onClose={ () => this.onCloseProfile() }
		          />
		      	</Modal>

				<div className="row row-header">
					{/*<div className="col-md-12 container-orange">
						<div className="header-orange">
							<p>Referencia en tecnología de limpieza</p>
						</div>
					</div>*/}
					<div className="col-lg-9">
						<div className="container-logo">
							<img src={ LogoSplendor } className="logo" onClick={ () => {
								if (this.props.location.pathname == '/') {
									window.location.reload();
								}
								else {
									this.props.history.push('/');
								}
							} } />
							<button className="btn-search" onClick={ () => {
								this.setState({
									show_search: !this.state.show_search
								});
							} }>
								<img src={ this.state.show_search ? ArrowGrayIcon : SearchGrayIcon } />
							</button>
							<div className="container-buttons-menu">
								{
									this.state.show_search ? (
										<button className="container-input-search" onBlur={ () => {
											setTimeout(() => {
												this.setState({
													autocomplete_options: []
												});
											},300);
										} }>
											<form className="input-search" onSubmit={ (e: any) => {
												e.preventDefault();
												this.props.history.replace('/view-search?search=' + this.state.form.search);
											} }>
												<Input
													color="white"
													value={ this.state.form.search }
													name="search" 
													autocomplete="off"
													placeholder="Buscar producto"
													className={ this.state.autocomplete_options.length > 0 ? 'active' : '' }
													onChange={ (e: any) => {
														clearTimeout(this.timer);
														this.timer = setTimeout(() => {
															this.loadAutocomplete();
														},1000);
														this.change(e); 
													} } />
												<Button type="submit">
													<img src={ SearchWhiteIcon } />
													Buscar
												</Button>
											</form>
											{
												this.state.autocomplete_options.length > 0 && (
													<div className="autocomplete-list">
														{
															this.state.autocomplete_options.map((item: any) => {
																return (
																	<Link to={ `/view-product/${ item?.id }` }>
																		<button className="btn-autocomplete">
																			<img alt={ item?.name } src={ item?.preview_photo ? (Constants.BASE_STORAGE + item?.preview_photo) : (item?.photos?.length > 0 && item?.photos[0]?.file ? (Constants.BASE_STORAGE + item?.photos[0]?.file) : WhiteImage) } />
																			{ item.name }
																		</button>
																	</Link>
																)
															})
														}
													</div>
												)
											}
										</button>
									) : (
										<React.Fragment>
											<div className="menu-responsive">
												<div className="dropdown">
												  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
												    Menú
												  </button>
												  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
												    <a className="dropdown-item" href={ Constants.PUBLIC_WEB }>Inicio</a>
												    <a className="dropdown-item" href="#" onClick={ (e: any) => {
												    	e.preventDefault();
												    	this.props.history.replace('/view-category/' + Constants.PRODUCT_TYPES.PRODUCT)
												    } }>Tienda</a>
												    <a className="dropdown-item" href={ Constants.PUBLIC_WEB + 'quienes-somos' } target="_blank">Quiénes Somos</a>
												    {/*<a className="dropdown-item" href={ Constants.PUBLIC_WEB + 'quieres-ser-nuestro-proveedor' } target="_blank">¿Quieres ser nuestro proveedor?</a>*/}
												    <a className="dropdown-item" href={ Constants.PUBLIC_WEB + 'servicios' } target="_blank">Servicios</a>
												    <a className="dropdown-item" href={ Constants.PUBLIC_WEB + 'servizi' } target="_blank">Servizi</a>
												  </div>
												</div>
											</div>
											<div className="menu-normal">
												<a href={ Constants.PUBLIC_WEB } target="_blank">
													<button className="btn-menu">
														Inicio
													</button>
												</a>
												<button className="btn-menu" onClick={ () => this.props.history.replace('/view-category/' + Constants.PRODUCT_TYPES.PRODUCT) }>
													Tienda
												</button>
												<a href={ Constants.PUBLIC_WEB + 'quienes-somos' } target="_blank">
													<button className="btn-menu">
														Quiénes Somos
													</button>
												</a>
												{/*<a href={ Constants.PUBLIC_WEB + 'quieres-ser-nuestro-proveedor' } target="_blank">
													<button className="btn-menu">
														¿Quieres ser nuestro proveedor?
													</button>
												</a>*/}
												<a href={ Constants.PUBLIC_WEB + 'servicios' } target="_blank">
													<button className="btn-menu">
														Servicios
													</button>
												</a>
												<a href={ Constants.PUBLIC_WEB + 'servizi' } target="_blank">
													<button className="btn-menu">
														Servizi
													</button>
												</a>
											</div>
										</React.Fragment>
									)
								}
							</div>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="container-buttons-top">
							{
								this.props.user != null ? (
									<React.Fragment>
										<button className="btn-menu without-border" onClick={ () => { 
											this.setState({
												visible_profile: true
											});
										} }>
											<img src={ ProfileIcon } />
										</button>
										<button className="btn-menu" onClick={ () => { 
											this.setState({
												visible_password: true
											});
										} }>
											<img src={ LockIcon } />
										</button>
										<button className="btn-menu" onClick={ () => this.logout() }>
											<img src={ LogoutIcon } />
										</button>
									</React.Fragment>
								) : (
									<button className="btn-menu" onClick={ () => this.props.history.replace('/login') }>
										Acceder
									</button>
								)
							}
							<button className="item-cart" onClick={ () => {
								if (!this.state.open_cart) {
									this.setState({
										open_cart: true
									});
								}
							} } onBlur={ () => {
								this.setState({
									open_cart: false
								});
							} }>
								<button className="btn-menu without-border" onClick={ () => this.props.history.replace('/') }>
									Carrito / { Globals.formatMiles(this.props.cart.map((i: any) => i.product?.price * i.quantity).reduce((a: number, b: number) => a + b,0)) }
								</button>
								<div className="container-cart">
									<img src={ CartImage } />
									<p>{ this.props.cart.map((i: any) => i.quantity).reduce((a: number, b: number) => a + b,0) }</p>
								</div>
								{
									this.state.open_cart && (
										<div className="container-cart-header">
											{
												this.props.cart.length == 0 && (
													<p className="no-items">No se han agregado productos al carrito</p>
												)
											}

											<div className="container-scroll-products">
												{
													this.props.cart.map((item: any) => {
														return (
															<div className="item-product-cart">
																<img alt={ item?.product?.name } src={ item?.product?.preview_photo ? (Constants.BASE_STORAGE + item?.product?.preview_photo) : (item?.product?.photos?.length > 0 && item?.product?.photos[0]?.file ? (Constants.BASE_STORAGE + item?.product?.photos[0]?.file) : WhiteImage) } />
																<div>
																	<p className="name">{ item?.product?.name }</p>
																	<p>{ item?.quantity } x <span>{ Globals.formatMiles(item?.product?.price) }</span></p>
																</div>
															</div>
														)
													})
												}
											</div>

											<div className="container-buttons-cart">
												<p className="subtotal">Subtotal: { Globals.formatMiles(this.props.cart.map((i: any) => i.product?.price * i.quantity).reduce((a: number, b: number) => a + b,0)) }</p>
												<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
													<div className="btn-yellow" onClick={ () => this.props.history.replace('/view-cart') }>
														Ver carrito
													</div>
													{
														this.props.user != null && (
															<div className="btn-pay" onClick={ () => this.props.history.replace('/view-cart/payment') }>
																Pagar
															</div>
														)
													}
												</div>
											</div>
										</div>
									)
								}
							</button>
						</div>
					</div>
				</div>
				<div className="row row-subheader">
					<div className="col-md-3">
						<button className="item-select-container" onBlur={ () => {
							this.setState({
								open_categories: false
							});
						} }>
							<div className="item-select" style={{
								borderBottomRightRadius: this.state.open_categories ? '0px' : '5px',
								borderBottomLeftRadius: this.state.open_categories ? '0px' : '5px',
							}} onClick={ () => {
								this.setState({
									open_categories: !this.state.open_categories
								});
							} }>
								<img src={ CategoriesIcon } />
								<p>Categorías</p>
							</div>
							{
								this.state.open_categories && (
									<div className="container-absolute">
										<div className="item-select" onClick={ () => {
											this.props.history.replace('/view-category/' + Constants.PRODUCT_TYPES.COMBO);
											this.setState({
												open_categories: false
											});
										} }>
											<p>Combos</p>
										</div>
										{
											this.props.categories.map((item: any) => {
												return (
													<div className="item-select" onClick={ () => {
														this.props.history.replace('/view-category/' + Constants.PRODUCT_TYPES.PRODUCT + '/' + item?.id);
														this.setState({
															open_categories: false
														});
													} }>
														<p>{ item?.name }</p>
													</div>
												)
											})
										}
									</div>
								)
							}
						</button>
					</div>
					<div className="col-md-9 text-right">
						<div className="container-buttons">
							<button className="btn-white" onClick={ () => this.props.history.replace('/view-category/' + Constants.PRODUCT_TYPES.COMBO) }>
								Combos
							</button>
							<button className="btn-white without-border" onClick={ () => this.props.history.replace('/view-category/' + Constants.PRODUCT_TYPES.PRODUCT) }>
								Productos
							</button>
							{/*<a href={ Constants.PUBLIC_WEB + 'servizi' } target="_blank">
								<button className="btn-white without-border">
									Servizi
								</button>
							</a>*/}
							{/*<button className="btn-white without-border" onClick={ () => this.props.history.replace('/') }>
								Ofertas
							</button>*/}
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default withRouter(connect((state: RootState) => {
	return {
		user: state.user,
		contact_info: state.contact_info,
		product_types: state.product_types,
		cart: state.cart,
		categories: state.categories
	}
})(PublicHeader));